import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.DanhMucHuyenServiceProxy,
        ApiServiceProxies.DanhMucTinhServiceProxy,
        ApiServiceProxies.DanhMucXaServiceProxy,
        ApiServiceProxies.ComboBaseServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.TaiKhoanDoanhNghiepServiceProxy,
        ApiServiceProxies.DoanhNghiepServiceProxy,
        ApiServiceProxies.ChuKyServiceProxy,
        ApiServiceProxies.GiayCapPhepServiceProxy ,
        ApiServiceProxies.HoSoKeKhaiGiaServiceProxy ,
        ApiServiceProxies.MaKeKhaiGiaTTBServiceProxy,
        ApiServiceProxies.DanhMucQuocTichServiceProxy,
        ApiServiceProxies.DonViTinhServiceProxy,
        ApiServiceProxies.TaiLieuHuongDanServiceProxy,
        ApiServiceProxies.ThietLapChungServiceProxy,
        ApiServiceProxies.PhanHoiThongTinTTBServiceProxy,
        ApiServiceProxies.XuLyDuLieuLoiServiceProxy,
        ApiServiceProxies.KySoServiceProxy,
        ApiServiceProxies.QuanLyDangKyServiceProxy,
        ApiServiceProxies.NhomTTBYTServiceProxy,
        ApiServiceProxies.PhanLoaiTTBYTServiceProxy,
        ApiServiceProxies.DanhMucMaHangServiceProxy,
        ApiServiceProxies.DashBoardThongKeServiceProxy,
        ApiServiceProxies.DanhMucTenChungServiceProxy,
        ApiServiceProxies.DanhMucNhomTenChungServiceProxy,
        ApiServiceProxies.CauHinhTruyCapDuLieuServiceProxy,
        ApiServiceProxies.TaiKhoanServiceProxy,
        ApiServiceProxies.TraCuuServiceProxy,
        //ApiServiceProxies.DashboardServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
