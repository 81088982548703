import { AfterViewInit, Component, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '@delon/theme';

@Component({
    selector: 'layout-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements AfterViewInit {
    constructor(private el: ElementRef, private settings: SettingsService) {}
    totalWidthTopBarItem = 0;
    @HostListener('window:resize')
    onResizeWindow() {
        this.updateTopMenu();
    }
    private updateTopMenu() {
        if (window.innerWidth > 768) {
            this.settings.setLayout('collapsed', false);
        }
        if (window.innerWidth < this.totalWidthTopBarItem) {
            if (this.settings.layout.collapsed == false) {
                this.settings.setLayout('collapsed', true);
            }
        }
    }

    ngAfterViewInit() {
        // Tính totalWidthTopBarItem
        let elements = this.el.nativeElement.querySelectorAll('.sidebar-nav');
        let topBarMenu = elements[0];
        let topBarMenuItems = Array.from<any>(topBarMenu.children);
        this.totalWidthTopBarItem = topBarMenuItems.reduce((partialSum, a) => partialSum + a.offsetWidth, 0);
    }
}
