

<!-- <div class="alain-default__progress-bar" *ngIf="isFetching"></div> -->
<layout-header class="alain-default__header" *ngIf="!installationMode"></layout-header>
<layout-navbar *ngIf="!installationMode"></layout-navbar>
<layout-sidebar *ngIf="!installationMode"></layout-sidebar>
<section class="alain-fullscreen">
  
    <router-outlet></router-outlet>
</section>
<ng-template #settingHost></ng-template>
<nz-back-top></nz-back-top>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>


