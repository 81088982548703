import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { Menu, MenuService } from '@delon/theme';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService,
        private _ngAlainMenuService: MenuService,
    ) {}

    getMenu(): AppMenu {
        ``;
        return new AppMenu('MainMenu', 'MainMenu', [
            //new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'dashboard', '/app/admin/hostDashboard'),
            // new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'dashboard', '/app/main/dashboard'),
            // new AppMenuItem('Tenants', 'Pages.Tenants', 'bars', '/app/admin/tenants'),
            // new AppMenuItem('Editions', 'Pages.Editions', 'shopping', '/app/admin/editions'),

            // new AppMenuItem('Trang chủ', '', 'bar-chart', ''),
            new AppMenuItem('Thông tin doanh nghiệp', 'Pages.DoanhNghiep.ThongTinDoanhNghiep', 'bank', '/app/doanh-nghiep/thong-tin-doanh-nghiep'),

            new AppMenuItem('Quản lý TTBYT kê khai giá', 'Pages.KeKhaiGia.MaKeKhai', 'stock', '/app/ke-khai-gia/ma-ke-khai'),
            new AppMenuItem('Quản lý hồ sơ kê khai giá', 'Pages.KeKhaiGia.HoSoKeKhai', 'container', '/app/ke-khai-gia/ho-so-ke-khai'),

            new AppMenuItem('Quản lý doanh nghiệp', 'Pages.DoanhNghiep', 'home', '', [
                new AppMenuItem('Danh sách doanh nghiệp', 'Pages.DoanhNghiep.DanhSach', 'bars', '/app/doanh-nghiep/quan-ly-doanh-nghiep'),
                new AppMenuItem('Tài khoản doanh nghiệp', 'Pages.DoanhNghiep.TaiKhoan', 'user', '/app/doanh-nghiep/tai-khoan'),
            ]),
            new AppMenuItem('Pages_ThongKe', 'Pages.ThongKe', 'exception', '', [
                new AppMenuItem('Pages_ThongKe_BaoCaoTongHop', 'Pages.ThongKe.BaoCaoTongHop', 'bar-chart', '/app/thong-ke/bao-cao-tong-hop'),
                new AppMenuItem('Pages_ThongKe_TraCuuMaKeKhai', 'Pages.ThongKe.TraCuuMaKeKhai', 'search', '/app/thong-ke/tra-cuu-ma-ke-khai'),
            ]),

            new AppMenuItem('Danh mục', 'Pages.DanhMuc', 'bars', '', [
                new AppMenuItem('Địa chính', 'Pages.DanhMuc.DiaChinh', 'bars', '',[
                    new AppMenuItem('Quốc gia', 'Pages.DanhMuc.QuocTich', 'ordered-list', '/app/danh-muc/quoc-gia'),
                    new AppMenuItem('Tỉnh', 'Pages.DanhMuc.Tinh', 'ordered-list', '/app/danh-muc/tinh'),
                    new AppMenuItem('Huyện', 'Pages.DanhMuc.Huyen', 'ordered-list', '/app/danh-muc/huyen'),
                    new AppMenuItem('Xã', 'Pages.DanhMuc.Xa', 'ordered-list', '/app/danh-muc/xa'),
                ]),        
                new AppMenuItem('Đơn vị tính', 'Pages.DanhMuc.DonViTinh', 'ordered-list', '/app/danh-muc/don-vi-tinh'),
                new AppMenuItem('Nhóm TTBYT', 'Pages.DanhMuc.NhomTTBYT', 'unordered-list', '/app/danh-muc/nhom-ttbyt'),
                new AppMenuItem('Mã Hàng', 'Pages.DanhMuc.MaHang', 'unordered-list', '/app/danh-muc/ma-hang'),
                new AppMenuItem('Danh mục tên chung', 'Pages.DanhMuc.TenChung', 'profile', '/app/danh-muc/ten-chung'),
                new AppMenuItem('Danh mục nhóm của thiết bị', 'Pages.DanhMuc.NhomTenChung', 'project', '/app/danh-muc/nhom-cua-thiet-bi'),
                new AppMenuItem('Phân Loại rủi ro TTBYT', 'Pages.DanhMuc.PhanLoaiTTBYT', 'control', '/app/danh-muc/phan-loai-ttbyt'),
                new AppMenuItem('Quản lý giấy cấp phép lưu hành', 'Pages.KeKhaiGia.QuanLyGiayCapPhep', 'audit', '/app/ke-khai-gia/giay-cap-phep-luu-hanh'),
                new AppMenuItem('Quản lý giấy cấp phép nhập khẩu', 'Pages.KeKhaiGia.QuanLyGiayCapPhep', 'audit', '/app/ke-khai-gia/giay-cap-phep-nhap-khau')
            ]),

            new AppMenuItem('Hệ thống', 'Pages.HeThong', 'bars', '', [
                new AppMenuItem('Tài khoản người dùng', 'Pages.HeThong.QuanLyTaiKhoan', 'check-circle', '/app/he-thong/tai-khoan-nguoi-dung'),
                new AppMenuItem('Cài đặt', 'Pages.HeThong.CaiDat', 'setting', '/app/he-thong/cai-dat'),
            ]),
            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'team', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'safety', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'user', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'global', '/app/admin/languages'),
                new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'global', '/app/admin/languagetexts'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'book', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'setting', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'setting', '/app/admin/tenantSettings'),
                new AppMenuItem('Thiết lập chung', 'Pages.QuanTri.ThietLapChung', 'control', '/app/quan-tri/thiet-lap-chung'),
                new AppMenuItem('Xử lý dữ liệu lỗi', 'Pages.QuanTri.XuLyDuLieuLoi', 'warning', '/app/quan-tri/xu-ly-du-lieu-loi'),
                new AppMenuItem('Tài liệu hướng dẫn sử dụng', 'Pages.QuanTri.TaiLieuHuongDan', 'book', '/app/quan-tri/tai-lieu-huong-dan'),
            ]),
        ]);
    }

    mapToNgAlainMenu() {
        let menu = this.getMenu();
        let ngAlainRootMenu = <Menu>{
            text: this._localizationService.l(menu.name),
            group: false,
            hideInBreadcrumb: true,
            children: [],
        };
        this.generateNgAlainMenus(ngAlainRootMenu.children, menu.items);

        let ngAlainMenus = [ngAlainRootMenu];
        this._ngAlainMenuService.add(ngAlainMenus);
    }

    generateNgAlainMenus(ngAlainMenus: Menu[], appMenuItems: AppMenuItem[]) {
        appMenuItems.forEach((item) => {
            let ngAlainMenu: Menu;

            ngAlainMenu = {
                text: this._localizationService.l(item.name),
                link: item.route,
                icon: {
                    type: 'icon',
                    value: item.icon,
                },
                hide: !this.showMenuItem(item),
            };

            if (item.items && item.items.length > 0) {
                ngAlainMenu.children = [];
                this.generateNgAlainMenus(ngAlainMenu.children, item.items);
            }

            ngAlainMenus.push(ngAlainMenu);
        });
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
