export class CodeSystemConst {
  static gender = 'gender';
  static NguonKinhPhi = 'NguonKinhPhi';
  static DanToc = 'DanToc';
}

export class ComboBoxTableCode {
  static GetAllDoanhNghiepToDLL = 'GetAllDoanhNghiepToDLL';
  static GetAllTaiLieuHuongDanDLL = 'GetAllTaiLieuHuongDanDLL';
  static LOAI_TAI_LIEU_HUONG_DAN = 'LOAI_TAI_LIEU_HUONG_DAN';
  static DON_VI_TINH = 'DON_VI_TINH';
  static QUOC_GIA = 'QUOC_GIA';
  static NHOM_TTBYT = 'NHOM_TTBYT';
  static PHAN_LOAI_TTBYT = 'PHAN_LOAI_TTBYT';
  static MA_HANG = 'MA_HANG';
  static TEN_CHUNG = 'TEN_CHUNG';
  static NHOM_TEN_CHUNG = 'NHOM_TEN_CHUNG';
}

export class ComboBoxEnumCode {
  static Level = 'LEVEL';
  static TRANG_THAI_HO_SO_KE_KHAI_GIA = 'TRANG_THAI_HO_SO_KE_KHAI_GIA';
  static LOAI_GIAY_CAP_PHEP = 'LOAI_GIAY_CAP_PHEP';
  static TRANG_THAI_GIAY_CAP_PHEP = 'TRANG_THAI_GIAY_CAP_PHEP';
  static TRANG_THAI_MA_KE_KHAI = 'TRANG_THAI_MA_KE_KHAI';
  static LOAI_BAO_CAO = 'LOAI_BAO_CAO';
  static LOAI_THONG_KE = 'LOAI_THONG_KE';
  static THONG_KE_THEO = 'THONG_KE_THEO';
  static KHOANG_CHENH_LECH_GIA = 'KHOANG_CHENH_LECH_GIA';

}


export const Level_Phan_Quyen = [
  {
    displayText: "Bệnh viện",
    value: 31
  },
  {
    displayText: "Khoa/Phòng xét nghiệm",
    value: 32
  }
]


export class MimeTypeNames {
  static ApplicationXJavascript = "application/x-javascript";

  static AudioL24 = "audio/L24";

  static ApplicationXShockwaveFlash = "application/x-shockwave-flash";

  static ApplicationOctetStream = "application/octet-stream";

  static ApplicationAtomXml = "application/atom+xml";

  static TextCss = "text/css";

  static TextCmd = "text/cmd";

  static TextCsv = "text/csv";

  static ApplicationXDeb = "application/x-deb";

  static MultipartEncrypted = "multipart/encrypted";

  static MultipartSigned = "multipart/signed";

  static MessageHttp = "message/http";

  static ModelExample = "model/example";

  static ApplicationXDvi = "application/x-dvi";

  static ApplicationXmlDtd = "application/xml-dtd";

  static ApplicationJavascript = "application/javascript";

  static ApplicationEcmascript = "application/ecmascript";

  static ApplicationEdifact = "application/EDIFACT";

  static ApplicationEdiX12 = "application/EDI-X12";

  static MessagePartial = "message/partial";

  static MessageRfc822 = "message/rfc822";

  static TextXml = "text/xml";

  static VideoXFlv = "video/x-flv";

  static ImageGif = "image/gif";

  static TextXGwtRpc = "text/x-gwt-rpc";

  static ApplicationXGzip = "application/x-gzip";

  static TextHtml = "text/html";

  static ImageVndMicrosoftIcon = "image/vnd.microsoft.icon";

  static ModelIges = "model/iges";

  static MessageImdnXml = "message/imdn+xml";

  static ApplicationJson = "application/json";

  static ApplicationJsonPatch = "application/json-patch+json";

  static TextJavascript = "text/javascript";

  static ImagePjpeg = "image/pjpeg";

  static ImageJpeg = "image/jpeg";

  static TextXJqueryTmpl = "text/x-jquery-tmpl";

  static ApplicationVndGoogleEarthKmlXml = "application/vnd.google-earth.kml+xml";

  static ApplicationXLatex = "application/x-latex";

  static VideoXMatroska = "video/x-matroska";

  static ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  static ApplicationVndMsExcel = "application/vnd.ms-excel";

  static ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = "application/vnd.openxmlformats-officedocument.presentationml.presentation";

  static ApplicationVndMsPowerpoint = "application/vnd.ms-powerpoint";

  static ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  static ApplicationMsword = "application/msword";

  static MultipartAlternative = "multipart/alternative";

  static MultipartMixed = "multipart/mixed";

  static MultipartRelated = "multipart/related";

  static MultipartFormData = "multipart/form-data";

  static ApplicationVndMozillaXulXml = "application/vnd.mozilla.xul+xml";

  static AudioMpeg = "audio/mpeg";

  static AudioMp4 = "audio/mp4";

  static VideoMp4 = "video/mp4";

  static VideoMpeg = "video/mpeg";

  static ModelMesh = "model/mesh";

  static AudioBasic = "audio/basic";

  static VideoOgg = "video/ogg";

  static AudioOgg = "audio/ogg";

  static ApplicationOgg = "application/ogg";

  static ApplicationXopXml = "application/xop+xml";

  static ApplicationVndOasisOpendocumentGraphics = "application/vnd.oasis.opendocument.graphics";

  static ApplicationVndOasisOpendocumentPresentation = "application/vnd.oasis.opendocument.presentation";

  static ApplicationVndOasisOpendocumentSpreadsheet = "application/vnd.oasis.opendocument.spreadsheet";

  static ApplicationVndOasisOpendocumentText = "application/vnd.oasis.opendocument.text";

  static ApplicationXPkcs12 = "application/x-pkcs12";

  static ApplicationXPkcs7Certificates = "application/x-pkcs7-certificates";

  static ApplicationXPkcs7Mime = "application/x-pkcs7-mime";

  static ApplicationXPkcs7Certreqresp = "application/x-pkcs7-certreqresp";

  static ApplicationXPkcs7Signature = "application/x-pkcs7-signature";

  static ApplicationPdf = "application/pdf";

  static ImagePng = "image/png";

  static ApplicationPostscript = "application/postscript";

  static VideoQuicktime = "video/quicktime";

  static ApplicationXRarCompressed = "application/x-rar-compressed";

  static AudioVndRnRealaudio = "audio/vnd.rn-realaudio";

  static ApplicationRdfXml = "application/rdf+xml";

  static ApplicationRssXml = "application/rss+xml";

  static ApplicationSoapXml = "application/soap+xml";

  static ApplicationXStuffit = "application/x-stuffit";

  static ImageSvgXml = "image/svg+xml";

  static ImageTiff = "image/tiff";

  static ApplicationXTar = "application/x-tar";

  static TextPlain = "text/plain";

  static ApplicationXFontTtf = "application/x-font-ttf";

  static TextVcard = "text/vcard";

  static AudioVorbis = "audio/vorbis";

  static AudioVndWave = "audio/vnd.wave";

  static ApplicationFontWoff = "application/font-woff";

  static VideoWebm = "video/webm";

  static AudioWebm = "audio/webm";

  static AudioXMsWax = "audio/x-ms-wax";

  static AudioXMsWma = "audio/x-ms-wma";

  static VideoXMsWmv = "video/x-ms-wmv";

  static ModelVrml = "model/vrml";

  static ModelX3DXml = "model/x3d+xml";

  static ModelX3DBinary = "model/x3d+binary";

  static ModelX3DVrml = "model/x3d+vrml";

  static ApplicationXhtmlXml = "application/xhtml+xml";

  static ApplicationZip = "application/zip";
}
