<div class="alain-default__header-logo">
    <a target="_blank" class="alain-default__header-logo-link">
        <img class="alain-default__header-logo-expanded" src="./assets/logo-new.png" alt="{{settings.app.name}}" style="max-height:40px;" />
    </a>
</div>
<div class="alain-default__nav-wrap">
    <div class="alain-default__nav alain-default__nav-middle">
        <h1 style="    font-size: 20px;
        color: #392c86;
        margin: 9px 0px 0px 0px;font-weight:bold">Hệ thống quản lý kê khai giá</h1>
    </div>
    <ul class="alain-default__nav">
        <li>
            <div class="alain-default__nav-item hidden-pc" (click)="toggleCollapsedSidebar()">
                <i nz-icon nzType="menu-{{settings.layout.collapsed ? 'unfold' : 'fold'}}"></i>
            </div>
        </li>
        <li *ngIf="isImpersonatedLogin">
            <button (click)="backToMyAccount()" nz-button nzType="link" title="Quay lại tài khoản chính">
                <i nz-icon nzType="api" nzTheme="outline"></i>
            </button>
        </li>
        <li class="hidden-mobile">
            <header-user style="float: left;"></header-user>
        </li>
    </ul>
</div>